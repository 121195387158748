export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் அறிக"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களை தொடர்பு கொள்ள"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அடுத்தது"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முந்தைய"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மீண்டும்"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆங்கிலம்"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சீன"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மலாய்"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தமிழ்"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பர்மியர்"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிங்களவர்கள்"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெங்காலி"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிச்சயமாக வெளியேற விரும்புகிறீர்களா?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் வெற்றிகரமாக வெளியேறிவிட்டீர்கள்."])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செய்தி வெற்றிகரமாக அனுப்பப்பட்டது."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் செய்தியை அனுப்புவதில் பிழை."])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்களுடைய பெயரை பதிவு செய்யவும்."])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தயவுசெய்து உங்கள் மின்னஞ்சலை உள்ளிடவும்."])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரியான மின்னஞ்சலை உள்ளிடவும்."])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒரு செய்தியை உள்ளிடவும்."])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொற்கள் பொருந்தவில்லை."])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒரு பிராந்தியம் தேர்ந்தெடுக்கப்பட்டதையும், சரியான ஃபோன் எண்ணை உள்ளிட்டுள்ளதையும் உறுதிசெய்யவும்."])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் எண்ணுக்கு சரிபார்ப்புக் குறியீட்டை அனுப்பும் வரை காத்திருக்கவும்."])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வருக! நீங்கள் இங்கே இருப்பதில் நாங்கள் மகிழ்ச்சியடைகிறோம்"])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெற்றிகரமாக உள்நுழைந்துள்ளீர்கள், மீண்டும் வரவேற்கிறோம்"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சரியான மின்னஞ்சல் மற்றும் கடவுச்சொல்லை உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்."])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் சரியான பெயரை உள்ளிட்டுள்ளீர்கள் என்பதை உறுதிப்படுத்தவும்."])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் வெற்றிகரமாக பதிவு செய்துள்ளீர்கள்."])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல் மீட்டமைக்கப்பட்டது."])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர உங்கள் கடவுச்சொல்லை உள்ளிடவும்"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒன்று அல்லது அதற்கு மேற்பட்ட உள்ளீட்டு புலங்கள் நிபந்தனைகளை பூர்த்தி செய்யவில்லை."])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் மின்னஞ்சல் வெற்றிகரமாக அனுப்பப்பட்டது."])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்தப் படிப்பில் வெற்றிகரமாகச் சேர்ந்துள்ளீர்கள்."])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கருத்து வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது."])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் முதல் கேள்வியில் இருக்கிறீர்கள்!"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் தொடரும் முன் பதில் நிரப்பப்பட வேண்டும்!."])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் கடைசி கேள்வியில் உள்ளீர்கள், நிச்சயமாக உங்கள் பதில்களைச் சமர்ப்பிக்க விரும்புகிறீர்களா?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வினாடிவினா வெற்றிகரமாக சமர்ப்பிக்கப்பட்டது."])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெயர்"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முழு பெயர்"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல்"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொலைபேசி எண்"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செய்தி"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறுவனத்தின் பெயர்"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேசியம்"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிங்கப்பூர்"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பங்கு"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை உறுதிப்படுத்தவும்"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பிராந்தியம்"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஒரு முறை சரிபார்ப்புக் குறியீடு (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வயது"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["குழு"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இயல்புநிலை"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வயதானவர்கள்"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புலம் பெயர்ந்தவர்"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாலினம்"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாலினத்தைத் தேர்ந்தெடுக்கவும்"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆண்"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெண்"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மற்றவை"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆங்கில புலமை"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தேர்ச்சியைத் தேர்ந்தெடுக்கவும்"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மிகவும் ஏழை"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஏழை"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சராசரி"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நல்ல"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மிகவும் நல்லது"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் தன்னார்வலராக இருப்பதற்கு 5 நிமிடங்கள் ஒதுக்க வேண்டுமா?"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் டிஜிட்டல் தன்னார்வலர்கள் உலகில் எங்கிருந்து வேண்டுமானாலும் எங்களுக்கு உதவுகிறார்கள். நீங்கள் ஓய்வில் இருக்கும்போது மட்டுமே எங்கள் அமைப்பு உங்களுக்குத் தெரிவிக்கும், மேலும் எங்கள் புலம்பெயர்ந்த குடும்பத்திற்குப் பயிற்சி அல்லது புதிய வகுப்புகளை உருவாக்குவதில் உங்கள் ஓய்வு நேரத்தைச் செலவிட உங்களை அனுமதிக்கிறது."])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சமர்ப்பிக்கவும்"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனுப்பு"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள்ளிடவும்"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு செய்யுங்கள்"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதுப்பிக்கவும்"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழி"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடரவும்"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள்நுழையவும்"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மீட்டமை"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP பெறவும்"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆன்லைன் படிப்பு"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலவசம்"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைவருக்கும் மற்றும் எவருக்கும் கருவிகள் மற்றும் ஆதாரங்களை வழங்குதல்"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எந்த செலவும் இல்லாமல் தங்களை மேம்படுத்திக்கொள்ளுங்கள்."])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் பார்க்க"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடங்குங்கள்"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பல மொழி ஆதரவு"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் வளர உதவும் படிப்புகள்"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலவச படிப்புகள், எப்போதும்"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் படைப்புகள்"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறமை"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வளர்ச்சி"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பயிற்சி"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிங்கப்பூரில் வலுவான பாத்திரங்களை ஏற்றுக்கொள்வதற்காக வெளிநாட்டுத் தொழிலாளர்கள் தங்கள் அறிவாற்றல் திறன்களை மேம்படுத்த உதவுவதும், மேலும் அவர்கள் தங்களை மேம்படுத்திக் கொள்ளவும் மேம்படுத்திக்கொள்ளவும் சிறந்த வாய்ப்பை வழங்குவதே எங்கள் நோக்கம்."])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆன்லைன் கற்பித்தல் கற்றல்"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறன்களை வளர்த்தல்"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வாழ்க்கையை மேம்படுத்துதல்"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வளருங்கள்"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள்"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எதிர்காலம் எங்களுடன்!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் திறமைகளை மேம்படுத்த உதவும் வகையில் வடிவமைக்கப்பட்ட படிப்புகள்."])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த பயிற்றுனர்கள்"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகள் உள்ளன"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெவ்வேறு மொழிகள்"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேம்படுத்தப்பட்ட படிப்புகள்"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நமது"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சமீபத்திய படிப்புகள்"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எடுத்துக்கொள்வது"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்வி"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அடுத்தவருக்கு"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொழில்நுட்பத்துடன் நிலை"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் வளர உதவும் படிப்புகள்"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலவச ஆன்லைன் படிப்பு"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைவருக்கும் மற்றும் எவருக்கும் தங்களை மேம்படுத்துவதற்கான கருவிகள் மற்றும் வளங்களை வழங்குதல்"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செலவில்லாமல்."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடத்தைத் தொடங்குங்கள்"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நாங்கள் உங்களுக்கு உதவுவோம்"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["திறமை"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வளர்ச்சி"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மற்றும் பயிற்சி"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புலம்பெயர்ந்த தொழிலாளர்களின் திறன்கள் மற்றும் திறன்களை மேம்படுத்துவதன் மூலம் அவர்களின் வாழ்க்கையை மேம்படுத்துவது, அதன் மூலம் அதிகரித்த வாய்ப்புகள் மற்றும் மேம்பட்ட வாழ்வாதாரத்திற்கான கதவுகளைத் திறப்பதே எங்கள் நோக்கம். புலம்பெயர்ந்த தொழிலாளர்கள் நம்பிக்கையுடனும் திறமையுடனும் சாத்தியக்கூறுகள் நிறைந்த உலகிற்குச் செல்ல நாங்கள் கடமைப்பட்டுள்ளோம்."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் அறிக"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த பயிற்றுனர்கள்"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகள் உள்ளன"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெவ்வேறு மொழிகள்"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் சமீபத்திய படிப்புகள்"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விவரங்களைப் பார்க்கவும்"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் படிப்புகள்"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களை பற்றி"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் வளர உதவும் படிப்புகள்"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களுடன் வளருங்கள்"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growstudio க்கு வரவேற்கிறோம்"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd ஆல் தொடங்கப்பட்டது, Grow Studio என்பது வெளிநாட்டுத் தொழிலாளர்களை கற்கவும் வளரவும் உதவுவதற்காக அர்ப்பணிக்கப்பட்ட இலவச ஆன்லைன் தளமாகும். சிங்கப்பூர் மேலாண்மைப் பல்கலைக்கழகம் மற்றும் Superink Pte Ltd ஆகியவற்றின் கூட்டு ஆதரவுடன், வெளிநாட்டுத் தொழிலாளர்கள் ஆங்கிலம், உடல்நலம், நிதி மற்றும் பிற திறன்களைக் கற்க உதவுவதை நோக்கமாகக் கொண்டுள்ளோம்."])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" பயனுள்ள கற்றலைச் செயல்படுத்துவதற்கு மதிப்பீடுகளுடன் எங்கள் படிப்புகள் பல்வேறு மொழிகளில் உள்ளடக்கப்பட்டுள்ளன. கூடுதலாக, கோவிட்-19 தொற்று நோயுடன், க்ரோ ஸ்டுடியோ வெளிநாட்டுத் தொழிலாளர்கள் பாரம்பரிய வகுப்பறை அமைப்பிற்கு ஆளாகாமல், புதிய திறன்களையும் அறிவையும் பெற அனுமதிக்கிறது."])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகளை கண்டறியவும்"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களைத் தேர்ந்தெடுக்கவும்"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்த தளத்தில் வழங்கப்படும் ஒவ்வொரு பாடமும் வகுப்பும் இலவசம். இது அனைவருக்கும், குறிப்பாக வெளிநாட்டு பணியாளர்களுக்கு திறந்திருக்கும்."])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் நோக்கம்"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எந்தவொரு செலவின்றி தங்களை மேம்படுத்திக் கொள்வதற்கான கருவிகள் மற்றும் ஆதாரங்களை அனைவருக்கும் மற்றும் யாருக்கும் வழங்கவும்."])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் தரிசனங்கள்"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் உள்ளூர் புலம்பெயர்ந்த சமூகத்தை வளர்ப்பதற்கு, அவர்களின் திறன்கள் மற்றும் தகவல்தொடர்புகளை மேம்படுத்துதல்."])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மொத்த பயிற்றுனர்கள்"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகள் உள்ளன"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெவ்வேறு மொழிகள்"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் அணி"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அறிமுகப்படுத்துகிறது"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் குழுவை சந்திக்கவும்"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்கள் குழுவில் பல்வேறு பின்னணிகள் மற்றும் திறன்கள் கொண்ட நிபுணர்கள் உள்ளனர், ஒவ்வொருவரும் அவரவர் தனிப்பட்ட பார்வையை அட்டவணையில் கொண்டு வருகிறார்கள்."])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிர்வாக இயக்குனர்"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தகவல் தொழில்நுட்ப மேம்பாட்டு இயக்குனர்"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிர்வாக இயக்குனர்"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கல்வி இயக்குனர்"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஐடி டெவொப்ஸ் தலைவர்"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொண்டர்"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இன்றே தன்னார்வத் தொண்டராகப் பதிவு செய்து, புலம்பெயர்ந்த தொழிலாளர்களின் கல்விக்கு உதவுங்கள்"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்தப் படிவத்தை நிரப்பவும், விரைவில் உங்களைத் தொடர்புகொள்வோம்."])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் கற்பிக்க வசதியாக இருக்கும் மொழிகளைத் தேர்ந்தெடுக்கவும்"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகள்"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களுடன் வளர எங்கள் வகுப்புகளை ஆராயுங்கள்"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகளை ஆராயுங்கள்"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வகை"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["குறிச்சொற்கள்"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடமொழியைத் தேர்ந்தெடுக்கவும்"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கற்கத் தொடங்க ஒரு பாடத்தைத் தேர்ந்தெடுக்கவும்"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் பார்க்க"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பொது விவரங்கள்"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடங்கள்"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வினாடி வினாக்கள்"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விலை"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இலவசம்"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு செய்"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விவரங்களைப் பார்க்கவும்"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிமிடம்"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மற்ற வீடியோக்கள்"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பற்றி"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் விவரங்களைப் பார்க்க ஒரு பாடத்தைத் தேர்ந்தெடுக்கவும்"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனைத்து"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தற்போது படிக்கிறார்"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["காணொளி"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வினாடி வினா"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வினாடி வினாக்கள்"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விளக்கக்காட்சி"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விளக்கக்காட்சி இல்லை"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பவர்பாயிண்ட்டைப் பதிவிறக்கவும்"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களை தொடர்பு கொள்ள"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்பில் இருங்கள்"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மேலும் வளர எங்களை தொடர்பு கொள்ளவும்"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல் மூலம் எங்களை அணுக தயங்க"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["முகவரி"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனுப்பு"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உங்கள் பெயர்"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்பு கொள்ளவும்"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல்"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["செய்தி"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனுப்பு"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சுயவிவரம்"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தனிப்பட்ட"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சொந்த விவரங்கள்"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெயர்"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொலைபேசி எண்"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல்"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["குழு"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இயல்புநிலை"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வயதானவர்கள்"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புலம் பெயர்ந்தவர்"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறுவனம்"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறுவனத்தின் விவரங்கள்"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிறுவனத்தின் பெயர்"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பங்கு"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புதுப்பிக்கவும்"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அழி"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்புகள்"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவுசெய்யப்பட்ட படிப்புகள்"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் எந்தப் படிப்புகளிலும் சேரவில்லை"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["படிப்பு முன்னேற்றம்"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வினாடி வினா முன்னேற்றம்"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடுகள்"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பீடுகள் முடிக்கப்பட்டன"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நீங்கள் எந்த மதிப்பீடுகளையும் முடிக்கவில்லை"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலை"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மதிப்பெண்"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிலைப் பார்க்கவும்"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆசிரியர் கருவிகள்"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள்நுழையவும்"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல் அல்லது தொலைபேசி எண்"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள்நுழைய"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கணக்கு இல்லையா?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை மறந்துவிட்டீர்களா?"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை மீட்டமைக்க"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அல்லது உடன் உள்நுழையவும்"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கவலையாக இருக்கிறேன்? இந்த ஹாட்லைனை அழைக்கவும்: +65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொலைபேசி எண்"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP பெறவும்"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஏற்கனவே ஒரு கணக்கு உள்ளதா?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உள்நுழையவும்"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பதிவு செய்ய முடியவில்லையா?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களை இங்கே தொடர்பு கொள்ளவும்"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கடவுச்சொல்லை மீட்டமைக்க"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல்"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனுப்பு"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google உள்நுழைவு தற்போது கிடைக்கவில்லை."])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook உள்நுழைவு தற்போது கிடைக்கவில்லை."])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMU மற்றும் SuperINK ஆல் ஆதரிக்கப்படுகிறது"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ஆங்கிலம்"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சீன"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மலாய்"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தமிழ்"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பர்மியர்"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சிங்களவர்கள்"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெங்காலி"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சீ கிம் சியோக் கன்ஸ்ட்ரக்ஷன் கோ. (Pte) லிமிடெட் மூலம் கட்டப்பட்டது"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வீடு"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களை பற்றி"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாடநெறி"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வினாடி வினா"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பாத்திரம்"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொடர்பு கொள்ளவும்"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["சுயவிவரம்"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["புகுபதிகை / பதிவு செய்தல்"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["வெளியேறு"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["தொண்டர்"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எந்தவொரு செலவின்றி தங்களை மேம்படுத்திக்கொள்ளும் கருவிகள் மற்றும் வளங்களை அனைவருக்கும் மற்றும் யாருக்கும் வழங்குதல்."])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இணைப்புகள்"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["எங்களை தொடர்பு கொள்ள"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விரைவான இணைப்பு"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["பெயர்"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["மின்னஞ்சல்"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["விளக்கம்"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["அனுப்பு"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["கட்டப்பட்டது"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["உதவியவா்"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["இந்த பாடத்திட்டத்தில், எழுத்துக்கள், எண்கள் மற்றும் இலக்கணம் போன்ற ஆங்கில அடிப்படைகளை கற்றுக்கொள்வதன் மூலம் தொடங்குவீர்கள். அதைத் தொடர்ந்து, உங்கள் குடும்பத்தினருடனும் நண்பர்களுடனும் தொடர்பு கொள்ள உதவும் எளிய உரையாடல்களை எவ்வாறு செய்வது என்பதை நீங்கள் கற்றுக் கொள்வீர்கள். உணவை ஆர்டர் செய்தல் மற்றும் மருத்துவரிடம் செல்வது போன்ற பொதுவான சூழ்நிலைகளில் எப்படி உரையாடுவது என்பதையும் நீங்கள் கற்றுக் கொள்வீர்கள்."])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலை 1ல் இருந்து தொடர்ந்து, ஆங்கில இலக்கணங்களான காலங்கள், நிறுத்தற்குறிகள் மற்றும் இணைப்புகள் பற்றி மேலும் ஆழமாக அறிந்து கொள்வீர்கள். கூடுதலாக, உங்கள் உணர்வுகளை வெளிப்படுத்துதல், மின்னஞ்சல்களை எழுதுதல் மற்றும் பணி தொடர்பான பல்வேறு சூழ்நிலைகளில் உரையாடுதல் போன்ற தனிப்பட்ட மற்றும் பணி அமைப்புகளில் அதிக உரையாடல் திறன்களைக் கற்றுக்கொள்வீர்கள்."])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["நிலை 3 ஐ அடைந்து, முன்மொழிவுகள், மாதிரி வினைச்சொற்கள் போன்ற மிகவும் சிக்கலான தலைப்புகளைக் கையாள்வதில் நாங்கள் முன்னேறுகிறோம், பின்னர் வாசிப்பு மற்றும் புரிந்துகொள்வதற்கான அத்தியாவசிய திறன்களை எடுப்பதில் முன்னேறுவோம். இறுதியாக, நீங்கள் கற்றுக்கொண்ட அனைத்தையும் இறுதி மதிப்பாய்வு அமர்வில் முடித்து, உங்களின் எதிர்கால ஆங்கிலப் படிப்புகளில் உங்களுக்கு உதவ ஆலோசனைகளை வழங்குவோம்."])}
  }
}